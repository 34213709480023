import { useState, useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { Api } from './app-controller';

// ----------------------------------------------------------------------
const KEY = 'logo';

export default function App() {
  const host = window.location.host.split(":")[0];
  const [logo, setLogo] = useState(`${Api.url}getLogo?logo=true`);
  /* Guarda el logo en localstorage */
  useEffect(() => {
    localStorage.setItem(KEY, JSON.stringify(logo));
  }, [logo]);

    /* logo pestaña */
    useEffect(() => {
      const logoUrl = `${Api.url}getLogo?logo=false`;
  
      // Guardar el logo de la pestaña en localStorage
      localStorage.setItem('logoPestana', JSON.stringify(logoUrl));
  
      // Actualizar el favicon después de guardar el logo en localStorage
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = logoUrl;
      }
    }, []);

  const inicialColor =
    localStorage && localStorage.getItem('color')
      ? JSON.parse(localStorage.getItem('color'))
      : '#00AB55';

  const [color, setColor] = useState(inicialColor);

  useEffect(() => {
    localStorage.setItem('color', JSON.stringify(color));
  }, [color]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(`${Api.url}getColor`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (localStorage && localStorage.getItem('color')) { // si existe color
            if (localStorage.getItem('color') !== data) { // si son diferentes  se guarda nuevo en localSotage
              localStorage.setItem('color', JSON.stringify(data));
              setColor(data);
            } 
          }
        }
      });
  }, []);

  return (
    <ThemeConfig color={color}>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
